import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
//import { BLOCKS, MARKS } from "@contentful/rich-text-types";
//import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { navigate } from "@reach/router";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
//import marked from "marked";

const Publication = ({ data, location }) => {
  const intl = useIntl();

  const post = data.contentfulClinicalPublication;

  // 公開設定がfalse / nullの場合はリダイレクト
  if (!post.title) {
    navigate("/research/");
    return null;
  }

  //const Bold = ({ children }) => <p className="bold">{children}</p>;
  //const Text = ({ children }) => <p className="align-center">{children}</p>;
  //const options = {
  //  renderMark: {
  //    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  //  },
  //  renderNode: {
  //    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  //  },
  //  renderText: (text) => text.replace("!", "?"),
  //};

  return (
    <Layout location={location} mode="beige">
      <Seo title={post.title && post.title} description />
      <div className="post mediaroom_post bg-bge">
        <div className="container">
          <h1 className="h1">{post.title}</h1>

          <p className="message-label-bold txt txt-rot mb-2">
            Clinical Publication
          </p>
          <p className="font-weight-bold fz14">
            {post.publishDate && post.publishDate}
          </p>
        </div>
        <div className="container pb-8">
          <div className="flex-row justify-content-between">
            <div className="flex-column-6">
              <h2 className="h3 txt font-weight-semibold">Results</h2>
              <p>{post.results && post.results.results}</p>

              {post.conclusions ? (
                <h2 className="h3 txt font-weight-semibold mt-6">
                  Conclusions
                </h2>
              ) : (
                ""
              )}
              <p>{post.conclusions && post.conclusions.conclusions}</p>
            </div>
            <div className="flex-column-5">
              <p className="message-label-bold txt txt-rot mb-2">Authors</p>
              <p>{post.authors && post.authors.authors}</p>

              <p className="message-label-bold txt txt-rot mb-2">DOI number</p>
              <p>
                <a
                  className="font-weight-bold mb-4 tdu"
                  href={`https://doi.org/${post.doiNumber}`}
                >
                  {post.doiNumber && `https://doi.org/${post.doiNumber}`}
                </a>
              </p>

              <p className="message-label-bold txt txt-rot mb-2">Citation</p>
              <p>{post.citation && post.citation.citation}</p>

              {post.pdf ? (
                <div className="mt-4">
                  <a href={post.pdf.file.url}>PDF</a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* <div className="main">
            <p>title: {post.title && post.title}</p>
            <p>slug: {post.slug && post.slug}</p>
            <p>publishDate: {post.publishDate && post.publishDate}</p>
            <p>results: {post.results && post.results.results}</p>
            <p>
              conclusions: {post.conclusions && post.conclusions.conclusions}
            </p>
            <p>authors: {post.authors && post.authors.authors}</p>
            <p>doiNumber: {post.doiNumber && post.doiNumber}</p>
            <p>citation: {post.citation && post.citation.citation}</p>
            <p>pdf file url: {post.pdf && post.pdf.file.url}</p>
          </div> */}
        </div>
      </div>
      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 txt mb-5">
            {intl.locale === "en" ? `Media Room` : `Multimedia`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Clinical Publications`
                    : `Klinische Studien`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/research/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Recruiting Inc.` : `Karriere`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/recruit/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};
export default Publication;

export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    contentfulClinicalPublication: contentfulClinicalPublications(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      id
      title
      slug
      doiNumber
      citation {
        citation
      }
      results {
        results
      }
      conclusions {
        conclusions
      }
      authors {
        authors
      }
      pdf {
        file {
          url
        }
      }
      publishDate(formatString: "DD. MMMM YYYY")
      publishYear: publishDate(formatString: "YYYY")
      updatedAt(formatString: "YYYY/MM/DD")
      createdAt(formatString: "YYYY/MM/DD")
    }
  }
`;
